import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

function Generate() {
  const { error, isLoadingAuth, getAccessTokenSilently } = useAuth0();

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const script_value = formData.get("script");

    getAccessTokenSilently({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "write:script",
    }).then((accessToken) => {
      fetch(process.env.REACT_APP_API_URL + "/v1/generate_video", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
        },
        scope: "write:script",
        body: JSON.stringify({
          script: script_value,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(`Error ${response.status} connecting with server.`);
          }
          //throw response;
        })
        .then(
          (data) => {
            setMessage(JSON.stringify(data.message));
          },
          (err) => {
            setMessage(err.message);
          }
        );
    });
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  }

  const [message, setMessage] = useState(null);
  const [state, setState] = useState({ script: "" });

  if (isLoadingAuth) {
    return <div className="nc-centralize w3-text-white">Loading ...</div>;
  }
  if (error) {
    return <div className="nc-centralize w3-text-white">Error</div>;
  }

  return (
    <div className="nc-centralize w3-text-white">
      <div className="w3-left-align">
        <h2>Message: {message}</h2>

        <form onSubmit={handleSubmit}>
          <label>Script</label>
          <br />
          <textarea
            name="script"
            rows="20"
            cols="50"
            onChange={handleInputChange}
          ></textarea>
          <br />
          <label></label>
          <br />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}
export default Generate;
