import React from "react";
import {
  BrowserRouter as Router,
  NavLink,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./index.css";
import "./App.css";

import Forbidden from "./pages/Forbidden.js";
import Generate from "./pages/Generate.js";
import LoginButton from "./pages/LoginButton.js";
import LogoutButton from "./pages/LogoutButton.js";
import RequireAuth from "./pages/RequireAuth.js";
import Profile from "./pages/Profile.js";
import Start from "./pages/Start.js";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

const logo = process.env.PUBLIC_URL + "/logo.svg";

function App() {
  const {
    error,
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
    <div>
      <nav className="w3-text-white">
        <ul>
          <li></li>
          <li></li>
          <li>
            <NavLink to="/start">
              <img
                src={logo}
                width="24"
                height="24"
                alt="Finite Resolution logo"
              />
              Finite Resolution
            </NavLink>
          </li>
          {isAuthenticated && (
            <li>
              <NavLink to="/generate">Generate</NavLink>
            </li>
          )}
          <li className="nc-right w3-text-white">
            {isAuthenticated ? <LogoutButton /> : <LoginButton />}
          </li>
          {isAuthenticated && (
            <li className="nc-right w3-text-white">
              <NavLink to="/profile">{user.name}</NavLink>
            </li>
          )}
        </ul>
      </nav>

      <section>
        {/*
        <div id="status">
          Filler
        </div>
        */}
      </section>
      {/* A <Routes> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      {error &&
      error.error_description ===
        "Please verify your email before logging in." ? (
        <div className="w3-text-white w3-display-middle">
          Please verify your email then {"  "}
          <button onClick={loginWithRedirect}>Login</button>
        </div>
      ) : (
        <Routes>
          <Route path="/forbidden" element={<Forbidden />} />
          <Route path="/start" element={<Start />} />
          <Route
            path="/generate"
            element={
              <RequireAuth redirectTo="/forbidden">
                <Generate />
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth redirectTo="/forbidden">
                <Profile />
              </RequireAuth>
            }
          />
          <Route path="/" element={<Navigate to="/start" />} />>
        </Routes>
      )}
      <footer></footer>
    </div>
  );
}

function Shell() {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
        scope: "profile email write:script",
      }}
    >
      <Router>
        <App />
      </Router>
    </Auth0Provider>
  );
}

export default Shell;
