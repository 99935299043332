import React from "react";

const logo = process.env.PUBLIC_URL + "/logo.svg";

class Start extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="nc-centralize w3-display-container w3-text-white">
        {/* <div className="w3-display-topleft w3-padding-large w3-xlarge">
         <img src="images/FRLogo.svg" width="40" height="40" />
        Finite Resolution
      </div>
    */}
        <div className="w3-display-middle">
          <h1 className="w3-jumbo ">
            <img
              src={logo}
              width="80"
              height="80"
              alt="Finite Resolution logo"
            />
            Finite Resolution
          </h1>
          <hr
            className="w3-border-grey"
            style={{ margin: "auto", width: "40%" }}
          />
          <p className="w3-large w3-center">
            Imagine creating a feature length film within minutes...
          </p>
          <p className="w3-large w3-center">
            We are reinventing the future of cinema
          </p>
        </div>
      </div>
    );
  }
}

export default Start;
