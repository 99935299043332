import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

function Profile() {
  const { error, user, isLoadingAuth } = useAuth0();

  if (isLoadingAuth) {
    return <div className="nc-centralize w3-text-white">Loading ...</div>;
  }
  if (error) {
    return <div className="nc-centralize w3-text-white">Error</div>;
  }

  return (
    <div className="nc-centralize w3-text-white">
      <ul className="w3-left-align">
        <li>Nickame: {user.nickname}</li>
        <li>Name: {user.name}</li>
        <li>Verified: {JSON.stringify(user.email_verified)}</li>
      </ul>
    </div>
  );
}

export default Profile;
