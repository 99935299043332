import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export function RequireAuth({ children, redirectTo }) {
  const { error, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (
    error &&
    error.error_description === "Please verify your email before logging in."
  ) {
    return (
      <div className="w3-text-white w3-display-middle">
        Please verify your email then {"  "}
        <button onClick={loginWithRedirect}>Login</button>
      </div>
    );
  }

  if (isLoading) {
    return <div className="w3-text-white w3-display-middle">Loading ...</div>;
  }

  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

export default RequireAuth;
